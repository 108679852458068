import React, { useRef, useState } from 'react';
import logo from './logo.jpg'; // Import your image file
import axios from "axios";

const SurveyForm = () => {
    const [showTextarea, setShowTextarea] = useState(false);
    const [textar, setTextarea] = useState(false);

    const formRef = useRef(null);
    let req = ['interestInAncientEgypt',
        'discountPreference',
        'comprehensivePayment',
        'interactiveEventsPreference',
        'previousExperience',
        'excitement',
        'culturalPreference',
        'suggestions',
        'socialMediaPreference']
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(formRef.current);
        const answers = {};

        // Get answers from form data
        req.forEach((name) => {
            const value = formData.get(name);
            answers[name] = value;
        });

        const missingValues = Object.values(answers).some(value => !value);
        if (missingValues) {
            alert('Please fill out all the required fields.');
            return;
        }

        try {
            answers.suggestionsTextarea = textar
            const response = await axios.post('https://kemetbk.boxops.cloud/index.php', JSON.stringify(answers), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Show success alert
            alert('Form submitted successfully!');

            // Empty all HTML content
            document.body.innerHTML = '';
        } catch (error) {
            console.error(error);
        }
    };

    const handleRadioChange = (e) => {
        setShowTextarea(e.target.value === 'Yes');
    };

    return (

        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light text-center center">
                <img src={logo} height="130" className="d-inline-block align-top" alt="Logo" />
            </nav>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>How interested are you in seeing ancient Egyptian civilization?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interestInAncientEgypt" id="interestSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="interestSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interestInAncientEgypt" id="interestA" value="Agree" />
                            <label className="form-check-label" htmlFor="interestA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interestInAncientEgypt" id="interestN" value="Neutral" />
                            <label className="form-check-label" htmlFor="interestN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interestInAncientEgypt" id="interestD" value="Disagree" />
                            <label className="form-check-label" htmlFor="interestD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interestInAncientEgypt" id="interestSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="interestSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>What kind of discounts do you prefer?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="discountPreference" id="discountVacations" value="Vacations" />
                            <label className="form-check-label" htmlFor="discountVacations">Vacations</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="discountPreference" id="discountFamilies" value="Families" />
                            <label className="form-check-label" htmlFor="discountFamilies">Families</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="discountPreference" id="discountGroups" value="Groups" />
                            <label className="form-check-label" htmlFor="discountGroups">Groups</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="discountPreference" id="discountEarly" value="Early Booking" />
                            <label className="form-check-label" htmlFor="discountEarly">Early Booking</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="discountPreference" id="discountComprehensive" value="Comprehensive Gift Offers" />
                            <label className="form-check-label" htmlFor="discountComprehensive">Comprehensive Gift Offers</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Would you prefer that the payment be comprehensive for the entire program?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="comprehensivePayment" id="paymentSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="paymentSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="comprehensivePayment" id="paymentA" value="Agree" />
                            <label className="form-check-label" htmlFor="paymentA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="comprehensivePayment" id="paymentN" value="Neutral" />
                            <label className="form-check-label" htmlFor="paymentN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="comprehensivePayment" id="paymentD" value="Disagree" />
                            <label className="form-check-label" htmlFor="paymentD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="comprehensivePayment" id="paymentSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="paymentSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>How much do you prefer interactive events such as educational sessions and workshops?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interactiveEventsPreference" id="eventsSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="eventsSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interactiveEventsPreference" id="eventsA" value="Agree" />
                            <label className="form-check-label" htmlFor="eventsA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interactiveEventsPreference" id="eventsN" value="Neutral" />
                            <label className="form-check-label" htmlFor="eventsN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interactiveEventsPreference" id="eventsD" value="Disagree" />
                            <label className="form-check-label" htmlFor="eventsD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="interactiveEventsPreference" id="eventsSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="eventsSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Do you have any previous experiences visiting an entertainment center?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="previousExperience" id="experienceYes" value="Yes" />
                            <label className="form-check-label" htmlFor="experienceYes">Yes</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="previousExperience" id="experienceNo" value="No" />
                            <label className="form-check-label" htmlFor="experienceNo">No</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Do you feel excited about experiencing a new kind of experience?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="excitement" id="excitementSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="excitementSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="excitement" id="excitementA" value="Agree" />
                            <label className="form-check-label" htmlFor="excitementA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="excitement" id="excitementN" value="Neutral" />
                            <label className="form-check-label" htmlFor="excitementN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="excitement" id="excitementD" value="Disagree" />
                            <label className="form-check-label" htmlFor="excitementD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="excitement" id="excitementSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="excitementSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Do you prefer cultural performances, learning about the history and different ancient civilizations?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="culturalPreference" id="culturalSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="culturalSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="culturalPreference" id="culturalA" value="Agree" />
                            <label className="form-check-label" htmlFor="culturalA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="culturalPreference" id="culturalN" value="Neutral" />
                            <label className="form-check-label" htmlFor="culturalN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="culturalPreference" id="culturalD" value="Disagree" />
                            <label className="form-check-label" htmlFor="culturalD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="culturalPreference" id="culturalSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="culturalSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>What social media platform do you prefer to see ads on?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="socialMediaPreference" id="socialFacebook" value="Facebook" />
                            <label className="form-check-label" htmlFor="socialFacebook">Facebook</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="socialMediaPreference" id="socialInstagram" value="Instagram" />
                            <label className="form-check-label" htmlFor="socialInstagram">Instagram</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="socialMediaPreference" id="socialTiktok" value="Tiktok" />
                            <label className="form-check-label" htmlFor="socialTiktok">Tiktok</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="socialMediaPreference" id="socialYouTube" value="YouTube" />
                            <label className="form-check-label" htmlFor="socialYouTube">YouTube</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="socialMediaPreference" id="socialTwitter" value="Twitter" />
                            <label className="form-check-label" htmlFor="socialTwitter">Twitter</label>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Do you prefer seeing ads on social media to promote a project before opening?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="adsPreference" id="adsSA" value="Strongly agree" />
                            <label className="form-check-label" htmlFor="adsSA">Strongly agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="adsPreference" id="adsA" value="Agree" />
                            <label className="form-check-label" htmlFor="adsA">Agree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="adsPreference" id="adsN" value="Neutral" />
                            <label className="form-check-label" htmlFor="adsN">Neutral</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="adsPreference" id="adsD" value="Disagree" />
                            <label className="form-check-label" htmlFor="adsD">Disagree</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="adsPreference" id="adsSD" value="Strongly disagree" />
                            <label className="form-check-label" htmlFor="adsSD">Strongly disagree</label>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <label>Do you have any suggestions for a story you'd like to see in holograms soon?</label>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="suggestions" id="suggestionsYes" value="Yes" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="suggestionsYes">Yes</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="suggestions" id="suggestionsNo" value="No" onChange={handleRadioChange} />
                            <label className="form-check-label" htmlFor="suggestionsNo">No</label>
                        </div>
                    </div>
                    {showTextarea && (
                        <div>
                            <label htmlFor="suggestionsTextarea">Suggestions</label>
                            <textarea id="suggestionsTextarea" onChange={(s)=>{setTextarea(s.target.value)}} name='suggestionsTextarea' className="form-control" rows="3" placeholder="Enter your suggestions here" title="Suggestions" ></textarea>
                        </div>
                    )}

                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default SurveyForm;
