import './App.css';
// import Form from './component/form'
// import Header from './component/Header';
// import Popup from './component/popup'
import Kiro from './component/Kiro'

function App() {
  return (
    <div className="App">
      <div className='container'>
        {/* <header>
          <Header />
        </header>
        <div className='p-4'>
          <Form />
          <Popup />

        </div> */}
        <Kiro/>
        <div>
        </div>
      </div>
    </div>
  );
}

export default App;
